
import { getDictFLJ,
         getDictionary,
         getNewVfaDate,
         getStateRules } from '~/utils/butterUtils'
import { getVFAParameters } from '~/utils/VFAParameters'

export default {
  data () {
    return {
      voterType: null
    }
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    lang () {
      return this.$i18n.locale.toLowerCase()
    },
    selectedState () {
      return this.$store.state.selectedState
    },
    image () {
      return this.butterVFAGraphics ? this.butterVFAGraphics[this.selectedState.toLowerCase()] : null
    },
    dict () {
      if (this.butterDictionary) {
        return getDictionary(this.butterDictionary, this.lang, 'd', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    VFAParameters () {
      return getVFAParameters(this.$store)
    },
    LOCAL_STORAGE_SELECTED_STATE_TIME_TO_LIVE () {
      return this.VFAParameters.LOCAL_STORAGE_SELECTED_STATE_TIME_TO_LIVE
    },
    stateRules () {
      let scr1 = this.butterStateVotingRules.find(x => x.stateid.toLowerCase().slice(0, 2) === this.selectedState.toLowerCase())
      if (!scr1) {
        return {'iso': this.$route.params.stage}
      }
      return getStateRules(scr1, this.lang, this.$store.state.showDictionaryKeys)
    },
    butterStateVotingRules () {
      return this.$store.state.butterStateVotingRules
    },
    butterVFAGraphics () {
      return this.$store.state.butterVFAGraphics
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    }
  },
  methods: {
    saveRadio (voterType) {
      let oldlsObj = JSON.parse(localStorage.getItem('localstoragevotingstate'))
      let lsObj = JSON.stringify(Object.assign({}, oldlsObj, {'voterType': voterType}, {'expiryTime': getNewVfaDate().getTime()+this.LOCAL_STORAGE_SELECTED_STATE_TIME_TO_LIVE*1000}))
      localStorage.setItem('localstoragevotingstate', lsObj)

      this.$store.commit('saveVoterType', voterType)
    },
    getDictFLJ(dictItem, dict) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictFLJ(dictItem, dict)" in the return statement is really
       * the function getDictFLJ(dictItem, dict) that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictFLJ(dictItem, dict)
    },
  }
}
