import { render, staticRenderFns } from "./votertype.vue?vue&type=template&id=81865b70"
import script from "./votertype.vue?vue&type=script&lang=js"
export * from "./votertype.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VfaScrollUp: require('/opt/build/repo/components/VfaScrollUp.vue').default,At: require('/opt/build/repo/components/At.js').default})
